<template>
	<div class="container">
		<h2 class="label-header">Subject</h2>
		<button class="btn btn-primary mb-3 mt-1" @click="openModal" :disabled="isLoading">Add Subject</button>
		<div class="alert alert-danger alert-dismissable" v-if="showErrorPageAlert">
			{{ errorMessage }}
		</div>
		<div class="card">
			<div class="card-body" v-if="!isLoading">
				<div class="table-responsive">
					<table class="table">
						<thead>
							<tr>
								<th width="20px"></th>
								<th style="width: 150px;">Subject Code</th>
								<th>Subject Title</th>
								<th style="width: 150px;" v-if="parseInt(role) === 1">Action</th>
							</tr>
						</thead>
						<tbody>
							<template v-if="subjectList.length > 0">
								<tr v-for="(item, i) in subjectList" :key="i">
									<th>{{ i + 1 }}</th>
									<td style="width: 150px;">{{ item.subject_code }}</td>
									<td>{{ item.subject_title }}</td>
									<td v-if="parseInt(role) === 1">
										<button class="btn btn-sm btn-primary" style="margin-right: 10px;" @click.stop="editItem(item)">Edit</button>
										<button class="btn btn-sm btn-danger" @click="deleteItem(item)">Delete</button>
									</td>
								</tr>
							</template>
							<template v-if="subjectList.length === 0">
								<tr>
									<td style="text-align: center" colspan="4" v-if="parseInt(role) === 1">No Subject Added Yet</td>
									<td style="text-align: center" colspan="3" v-if="parseInt(role) !== 1">No Subject Added Yet</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card-body loader" v-if="isLoading">
				<div class="spinner-border"></div>
				<h6 style="margin-top: 15px; font-size: 17px;">{{ loadingMessage }}</h6>
			</div>
		</div>
		<div class="modal fade" ref="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
						<button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="alert alert-danger alert-dismissable" v-if="showErrorAlert">
							{{ errorMessage }}
						</div>
						<form @submit.prevent="onSubmit" novalidate>
							<div class="form-group mb-2">
								<label for="">Subject Code</label>
								<input type="text" v-model="vv.code.$model" placeholder="Enter subject code" class="form-control">
								<span class="error">{{ vv?.code?.$errors[0]?.$message }}</span>
							</div>
							<div class="form-group mb-3">
								<label for="">Subject Title</label>
								<input type="text" v-model="vv.title.$model" placeholder="Enter subject title" class="form-control">
								<span class="error">{{ vv?.title?.$errors[0]?.$message }}</span>
							</div>
							<div class="form-group mb-3">
								<img class="image" id="previewImage" :src="setImage(selectedImage)" alt="">
								<label class="custom-file-upload">
									<input type="file" @change="handleFileChange" id="profile-image-input" accept="image/*"/>
									Add Icon
								</label>
							</div>
							<div class="form-group">
								<button class="btn btn-primary" :disabled="vv.$invalid || isProcessing" style="margin-right: 15px;" v-if="editMode">
									<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									<span v-if="isProcessing" style="padding-left: 15px !important;">Updating.....</span>
									<span v-if="!isProcessing">Update</span>
								</button>
								<button class="btn btn-primary" :disabled="vv.$invalid || isProcessing" v-if="!editMode">
									<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									<span v-if="isProcessing" style="padding-left: 15px !important;">Saving.....</span>
									<span v-if="!isProcessing">Save</span>
								</button>
							</div>
						</form>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" @click="modal.hide()">Close</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { Modal } from 'bootstrap'
import { utmeFileMgt } from '@/services/utmeFileMgt.service'
import { storageService } from '@/services/storage.service'

export default {
	name: 'Course',
	setup() {
		const fform = reactive({
			code: '',
			title: ''
		})
		const rules = {
			code: { required },
			title: { required }
		}
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			isLoading: true,
			isProcessing: false,
			loadingMessage: 'Loading Subject ........',
			showErrorAlert: false,
			showErrorPageAlert: false,
			errorMessage: '',
			subjectList: [],
			modalTitle: 'Add Subject',
			modal: null,
			editMode: false,
			editIndex: 0,
			selectedImage: '',
			isImageChanged: false,
			role: 0
		};
	},
	mounted() {
		this.modal = new Modal(this.$refs.exampleModal)
		this.loadContent()
		this.isUserAdmin()
	},
	methods: {
		loadContent () {
			this.isLoading = true
			const folder = this.$route.params.folder
			utmeFileMgt.fetchSubject(folder)
				.then((response) => {
					this.isLoading = false
					this.subjectList = response.data.content
				})
				.catch((error) => {
					console.log(error.message)
				})
		},
		async isUserAdmin() {
			const role = await storageService.isUserAdmin()
			this.role = role
		},
		openModal () {
			this.modalTitle = 'Add Subject'
			this.editMode = false
			this.initialImage = ''
			this.selectedImage = ''
			this.fform.code = ''
			this.fform.title = ''
			this.modal.show()
		},
		setImage (image) {
			if(image.length === 0){
				return image
			}
			const index = image.indexOf('base64')
			if(index !== -1) {
				return image
			}else{
				return `${utmeFileMgt.apiUrl}/load-project-image/UTME/${this.$route.params.folder}/subject/${image}`
			}
		},
		async handleFileChange () {
			const elem = document.getElementById('profile-image-input')
			if(!elem.files[0]) return
			if (elem.files && elem.files[0]) {
				const image = await this.$imageHelper.compressImage(elem.files[0])
				this.selectedImage = image
				if(this.editMode){
					this.isImageChanged = true
				}
			}
		},
		onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;
			this.isProcessing = true
			this.showErrorAlert = false
			const postData = {
				folder: this.$route.params.folder,
				subject_id: this.editIndex,
				subject_code: this.fform.code,
				subject_title: this.fform.title,
				icon: this.selectedImage,
				old_icon: this.initialImage,
				icon_changed: false
			}
			if(this.editMode){
				if(!this.isImageChanged){
					postData.icon = ''
				}
				utmeFileMgt.updateSubject(postData)
					.then((response) => {
						this.isProcessing = false
						if(parseInt(response.data.message_code) === 200){
							this.modal.hide()
							this.fform.code = ''
							this.fform.title = ''
							this.selectedImage = ''
							this.loadContent()
						}else{
							this.showErrorAlert = true
							this.errorMessage = response.data.message
						}
					})
					.catch((error) => {
						console.log(error.message)
						this.isProcessing = false
						this.showErrorAlert = true
						this.errorMessage = 'Error Connecting To Server'
					})
			}else{
				utmeFileMgt.createSubject(postData)
					.then((response) => {
						this.isProcessing = false
						if(parseInt(response.data.message_code) === 200){
							this.modal.hide()
							this.fform.code = ''
							this.fform.title = ''
							this.selectedImage = ''
							this.loadContent()
						}else{
							this.showErrorAlert = true
							this.errorMessage = response.data.message
						}
					})
					.catch((error) => {
						console.log(error.message)
						this.isProcessing = false
						this.showErrorAlert = true
						this.errorMessage = 'Error Connecting To Server'
					})
			}
		},
		editItem (item) {
			this.fform.code = item.subject_code
			this.fform.title = item.subject_title
			this.selectedImage = item.subject_icon
			this.initialImage = item.subject_icon
			this.editIndex = item.subject_id
			this.editMode = true
			this.modalTitle = 'Edit Subject'
			this.modal.show()
		},
		deleteItem (item) {
			this.$swal({
				text: `Are you sure to delete this course?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, Delete',
				allowOutsideClick: false
			}).then((responses) => {
				if(responses.isConfirmed) {
					this.showErrorPageAlert = false
					this.isLoading = true
					const postData = {
						subject_id: item.subject_id,
						folder: this.$route.params.folder
					}
					utmeFileMgt.deleteSubject(postData)
						.then((response) => {
							this.isLoading = false
							if(parseInt(response.data.message_code) === 200){
								//this.modal.hide()
								this.loadContent()
							}else{
								this.showErrorPageAlert = true
								this.errorMessage = response.data.message
							}
						})
						.catch((error) => {
							console.log(error.message)
							this.isLoading = false
							this.showErrorPageAlert = true
							this.errorMessage = 'Error Connecting To Server'
						})
				}
			})
		}
	}
};	
</script>

<style scoped>
.container{
	
}
.card .card-body.loader{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
input[type="file"] {
	display: none;
}
.custom-file-upload{
	margin-top: 0px;
	display: inline-block;
	padding: 5px 10px;
	cursor: pointer;
	background-color: #09568d !important;
	box-shadow: 0px 0px 20px 6px #3a8cd0 inset !important;
	color: #FFFFFF;
	height: auto;
	border-radius: 5px;
	font-weight: 400 !important;
}
.image{
	width: 100px;
	height: 100px;
	border-radius: 5px;
	position: relative;
	border: 4px solid var(--paul-color-light);
	background-color: var(--paul-color-light);
	margin-right: 20px;
}
</style>